<template>
  <div class="main-wrapper" data-app>
    <div class="content">
      <div class="container p-0 p-sm-auto">
        <div v-if="loading">
          <v-row>
            <v-skeleton-loader
              class="mx-auto my-5 col-12 col-md-4"
              max-width="300"
              type="article"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mx-auto my-2 col-12 col-md-4"
              max-width="300"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mx-auto col-12 col-md-4"
              max-width="300"
              type="article"
            ></v-skeleton-loader>
          </v-row>
        </div>

        <div class="card card-profile shadow" v-if="company.data">
          <!-- HEAD CONTENT -->

          <v-row no-gutters class="justify-content-center align-items-center p-4">
            <h3 class="mb-3 text-center w-100">{{ company.name }}</h3>
            <div class="col-6 col-lg-3 order-lg-2 p-5 p-sm-2 p-lg-0">
              <a href="#">
                <img
                  v-if="company.logo && company.logo.route"
                  :src="company.logo.route"
                  class="w-100"
                  alt=""
                />
              </a>
            </div>

            <div class="col-lg-4 order-lg-3 text-lg-right pl-lg-2">
              <img
                class="country__flag position-relative top-0 right-0"
                :src="company.countries[0].flag"
                alt="Country to wich the franchising association belongs"
              />
              <p>{{ company.description }}</p>
            </div>
            <div
              class="col-lg-4 order-lg-1 text-lg-left align-self-lg-center pr-lg-2"
              v-if="company.data"
            >
              <v-row
                no-gutters
                class="mb-2"
                v-if="
                  company.data.find((item) => item.id === 6) &&
                  company.data.find((item) => item.id === 6).company_data
                "
              >
                <v-icon color="#172b4d">mdi-cellphone</v-icon>
                <a
                  class="text-link pl-2"
                  :href="`tel:${extractContent(
                    company.data.find((item) => item.id === 6).company_data.value
                  )}`"
                  v-html="
                    `${extractContent(
                      company.data.find((item) => item.id === 6).company_data.value
                    )}`
                  "
                >
                </a>
              </v-row>

              <v-row
                no-gutters
                class="mb-2"
                v-if="
                  company.data.find((item) => item.id === 5) &&
                  company.data.find((item) => item.id === 5).company_data.value
                "
              >
                <v-icon color="#172b4d">mdi-email</v-icon>
                <a
                  class="text-link pl-2"
                  :href="`mailto:${extractContent(
                    company.data.find((item) => item.id === 5).company_data.value
                  )} `"
                  v-html="
                    `${extractContent(
                      company.data.find((item) => item.id === 5).company_data.value
                    )}`
                  "
                >
                </a>
              </v-row>
              <v-row
                no-gutters
                class="mb-2"
                v-if="
                  company.data.find((item) => item.id === 4) &&
                  company.data.find((item) => item.id === 4).company_data.value
                "
              >
                <v-icon color="#172b4d">mdi-map-marker</v-icon>
                <p
                  class="m-0 pl-2"
                  v-html="company.data.find((item) => item.id === 4).company_data.value"
                ></p>
              </v-row>
              <v-row
                no-gutters
                class="mb-2"
                v-if="company.website && company.website != ''"
              >
                <v-btn
                  small
                  class="text-link"
                  :href="company.website"
                  :title="company.website"
                  target="_blank"
                  rel="noopener"
                >
                  <v-icon>mdi-web</v-icon>
                  Sitio Web
                </v-btn>
              </v-row>
            </div>
          </v-row>

          <!-- DIRECTIVE  -->
          <directors
            :items="company.directors"
            :title="'Junta Directiva'"
            v-if="company.directors.lenght > 0"
          ></directors>
          <!-- AFFILIATES -->
          <v-row no-gutters class="p-4 flex-column" v-if="companies.lenght > 0">
            <h3 class="px-4">Afiliados</h3>
            <v-row class="p-4 m-4 shadow-lg" v-for="(item, id) in companies" :key="id">
              <picture
                class="col-8 col-md-3 p-0 col-lg-4 d-flex align-items-center justify-content-center mx-auto"
              >
                <img
                  v-if="item.logo && item.logo.route"
                  :src="item.logo.route"
                  class="img-fluid"
                />
              </picture>
              <div class="col-12 col-md-9 col-lg-8">
                <h5 class="text-primary">{{ item.name }}</h5>
                <v-row no-gutters class="mb-2" v-if="item.website && item.website != ''">
                  <v-btn
                    class="mr-2"
                    small
                    outlined
                    v-for="(category, index) in item.categories"
                    :key="index"
                  >
                    {{ category.name }}
                  </v-btn>
                </v-row>
                <p>{{ item.description }}</p>
                <v-btn
                  small
                  outlined
                  class="text-link"
                  :href="company.website"
                  target="_blank"
                  rel="noopener"
                >
                  <v-icon>mdi-web</v-icon>
                  Sitio Web
                </v-btn>
              </div>
            </v-row>
            <v-pagination
              class="my-4"
              circle
              color="#0c2c7c"
              v-model="page"
              :length="pages"
            ></v-pagination>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Directors from 'Components/FrontendComponents/Directors.vue'
  export default {
    name: 'Asociations',

    components: { Directors },
    created() {
      this.getCompany()
      // this.getCompanies()
    },
    data() {
      return {
        company: {},
        companies: [],
        page: 1,
        pages: null,
        totalItems: null,
        itemsPerPage: 5,
        loadingCompanies: false,
        loadingCompaniesText: '',
      }
    },
    methods: {
      async getCompany() {
        this.loading = true
        axios.get('companies-by-slug/' + this.$route.params.slug).then((response) => {
          this.company = response.data
          this.loading = false
          if (this.company) {
            this.message = 'Esta información no está disponible actualmente'
          }
          this.getCompanies()
        })
      },
      async getCompanies() {
        this.loadingCompanies = true
        let searchString = ''
        if (this.filterActivated) {
          if (this.filters.search != '') {
            searchString = '&search=' + this.filters.search
          }
        }
        axios
          .get(
            'companies-list?active=1&type=0&page=' +
              this.page +
              '&company_id=' +
              this.company.id +
              searchString
          )
          .then((response) => {
            this.companies = response.data.data
            if (this.companies.length < 1) {
              this.loadingCompaniesText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.loadingCompanies = false
          })
      },
      extractContent(html) {
        return new DOMParser().parseFromString(html, 'text/html').documentElement
          .textContent
      },
    },
  }
</script>
<style lang="scss">
  .listing-item-container.list-layout .listing-item {
    width: 100%;
    height: 200px;
  }
  .country__flag {
    margin: 5px 0;
    width: 35px;
    height: 25px;
  }
  p > p,
  a > p {
    margin: 0;
  }
  .img-fluid {
    max-height: 200px;
    max-width: 200px;
  }
  .v-btn__content {
    text-transform: initial;
  }
</style>
